<template>
    <div>
        <div class="img">
            <img src="../assets/img/404.png" alt="">
            <div class="div" onclick="location.href='/home'"></div>
            <span @click="handelRefresh"></span>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            handelRefresh() {
                console.log('刷新');
            }
        },
    }
</script>

<style scoped>
.img{
    position: relative;
    padding-top: 150px;
    margin: auto;
    display: flex;
    justify-content: center;
}
.img .div{
    position: absolute;
    width: 128px;
    height: 44px;
    bottom: 48px;
    left: 43%;
    cursor: pointer;
}
.img span{
    position: absolute;
    width: 128px;
    height: 44px;
    bottom: 48px;
    right: 42.4%;
    cursor: pointer;
}
</style>